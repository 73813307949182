import React,{useState} from "react";
import Header from "./Header";
import Footer from "./Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faWallet,faLock } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios'
import toast, { Toaster } from "react-hot-toast";
Aos.init();

function LandingPage() {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };
      const [emailData, setEmailData] = useState({
        name: '',
        email:'',
        subject: '',
        message: '',
      });
    
      const handleChange = (e) => {
        setEmailData({
          ...emailData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        toast.loading('Please Wait..Sending Email',{
            duration:1000
          })
        try {
          const response = await axios.post('https://ramsumiranpal.in/apiv2/send-email', emailData);
          console.log(response,'response')
         
          if(response){
            toast.success('Thanks for Contacting Us.We will get back to you soon')
          }else{
            toast.error('something went wrong..please try again later')
          }
        } catch (error) {
            console.log(error)
          toast.error('Error sending email');
        }
      };
    
    return (
        <>
            <div className="font-poppins font-bold ">
                     <Toaster toastOptions={{ position: "top-center" }} />
                <div className=" w-full h-auto bg-cover bg-center bg-no-repeat block" style={{backgroundImage: "url('./assets/banner-bg.gif')"}}>
                    <div className="bg-[#01327F]/70"   >
                    <Header/>
                    <div className="SECTION-1 flex flex-col lg:flex-row justify-center ">
                    <div className="lg:w-[60%] mt-20">
                    <div className="w-full flex justify-center items-cente -translate-x-10 lg:-translate-x-0">
                    <div className="w-[40%] -translate-y-10 lg:-translate-y-20 translate-x-7 lg:translate-x-10"><img src="/assets/Vector 255.png"></img></div>
                    <div>
                    <p className="font-poppins text-white font-bold 2xl:text-8xl lg:text-6xl text-3xl text-left flex">Visionary Leader in Blockchain </p>
                    <p className="font-poppins text-white font-bold 2xl:text-8xl lg:text-6xl text-3xl text-left flex">and AI<img className="w-[50%] lg:w-[40%] -translate-y-6 lg:-translate-y-16 -translate-x-10" src="/assets/Vector 256.png"></img></p>
                    </div>
                    </div>
                    <div className="flex gap-5 -translate-y-16 hidden lg:block"> 
                    <button className="text-white font-Montserrat px-8 hover:border hover:border-white rounded-[17px] py-2 text-2xl hover:bg-[#41579f] transition duration-500" onClick={() => scrollToSection('what')}>Learn More</button>
                    <button className="text-white font-Montserrat px-8 hover:border hover:border-white rounded-[17px] py-2 text-2xl hover:bg-[#41579f] transition duration-500 ml-4" onClick={() => scrollToSection('contact')}>Contact<i class="fa-solid fa-arrow-right px-2"></i></button>
                    </div>
                    </div>
                    <div className="lg:w-[40%] flex justify-center -translate-y-16 lg:-translate-y-0">
                    <img className="w-8/12 " src="/assets/founder.png"></img>
                    </div>
                    <div className="flex justify-center gap-4 -translate-y-10 lg:hidden block mt-10"> 
                    <button className="text-white font-Montserrat px-8 border border-white rounded-[17px] py-2 text-lg bg-[#41579f] " onClick={() => scrollToSection('what1')}>Learn More</button>
                    <button className="text-white font-Montserrat px-8 border border-white rounded-[17px] py-2 text-lg bg-[#41579f] " onClick={() => scrollToSection('contact')}>Contact<i class="fa-solid fa-arrow-right px-2"></i></button>
                    </div>
                    </div>
                    
                    </div>
                </div>
                <div className="bg-gradient-to-r from-[#0243AD] to-[#4C9EEF] w-full h-auto md:py-4 py-2">
                    <div className="container mx-auto hover:translate-x-10 transition duration-1000 ease-in-out">
                        <h2 className="text-[#FFFFFFA1] font-Playfair font-bold lg:text-[85px] md:text-[65px] sm:text-[45px] text-[35px] tracking-widest">Ram Sumiran Pal</h2>
                    </div>
                </div>
                {/* what it does section */}
                 <div className="lg:block hidden SECTION-2-WEB">
                <div className="SECTION-2 w-[100%] bg-no-repeat bg-center bg-cover   bg-white/30 group " style={{backgroundImage:"url(/assets/rsp.jpeg)"}} id="what">
                    <div className=" w-[100%] group-hover:backdrop-blur-sm 2xl:py-28 xl:py-20 lg:py-12 px-3 pb-5">
                        <h2 className="lg:w-[65%] md:w-[80%] contrast-200 sm:w-[90%] w-full text-center mx-auto font-Montserrat group-hover:opacity-100 opacity-0 transition duration-1000 ease-in-out tracking-widest text-white md:text-5xl sm:text-4xl text-3xl py-10 ">What He Does !  </h2>
                        <div className="flex items-center flex-col gap-10 ">
                            <div className="bg-[#FAFAFA0D] lg:p-6 p-3 group-hover:opacity-100 opacity-0 transition duration-1000 ease-in-out py-10 bg-no-repeat lg:bg-cover  bg-auto bg-center lg:w-[65%]  md:w-[95%] w-full mr-auto " data-wow-duration="2s" data-wow-delay="5s" style={{backgroundImage: "url('/assets/rectangle.png')"}}>
                                <div className="flex flex-col gap-5 lg:gap-0 lg:flex-row items-center justify-between">
                                    <div className="w-[30%] flex  items-center justify-center gap-2">
                                        <FontAwesomeIcon icon={faWallet} className='text-white z-20 relative'/>
                                        <h4 className="font-semibold text-white text-sm">Business Consulting</h4>
                                        
                                    </div>
                                    <div className="pl-2">
                                        <p className="lg:text-md text-sm text-base text-white">Expert in sales, marketing, and community development through the direct sales industry.  </p>
                                    </div>
                                </div>
                            </div> 
                            <div className="bg-[#FAFAFA0D] lg:p-6  bg-no-repeat lg:bg-cover bg-auto bg-center lg:w-[65%]  md:w-[85%] md:w-[95%] w-full ml-auto  wow slideInRight group-hover:opacity-100 opacity-0 transition duration-1000 ease-in-out" data-wow-duration="2s" data-wow-delay="5s" style={{backgroundImage: "url('/assets/rectangle.png')"}}>
                                <div className="flex flex-col gap-5 lg:gap-0 lg:flex-row  items-center justify-between mt-5">
                                    <div className="w-[50%] flex  items-center justify-center gap-2">
                                        <FontAwesomeIcon icon={faLock} className=' text-white  z-20 relative'/>
                                        <h4 className="font-semibold text-white text-sm ">Blockchain Pioneer</h4>
                                        
                                    </div>
                                    <div className="pl-2">
                                <p className="lg:text-md text-sm text-base text-white">Shaping the future of crypto with a powerful new tool that offers transparency in crypto trading and exchanges, helping communities dive deep into cryptocurrency projects with confidence.    </p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
                </div>
                <div className="lg:hidden block SECTION-2-MOBILE">
                <div className="SECTION-2 w-[100%] bg-no-repeat bg-center bg-cover   bg-white/30 group " style={{backgroundImage:"url(/assets/rsp.jpeg)"}} id="what1">
                <div className=" w-[100%] backdrop-blur-sm 2xl:py-28 xl:py-20 lg:py-12 px-3 pb-5">
                    <h2 className="lg:w-[65%] md:w-[80%] contrast-200 sm:w-[90%] w-full text-center mx-auto font-Montserrat  transition duration-1000 ease-in-out tracking-widest text-white md:text-5xl sm:text-4xl text-3xl py-10 ">What He Does !  </h2>
                    <div className="flex items-center flex-col gap-10 " >
                        <div className="bg-[#FAFAFA0D] lg:p-6 p-3  transition duration-1000 ease-in-out py-10 bg-no-repeat lg:bg-cover  bg-auto bg-center lg:w-[65%]  md:w-[95%] w-full mr-auto "  style={{backgroundImage: "url('/assets/rectangle.png')"}}>
                            <div className="flex flex-col gap-5 lg:gap-0 lg:flex-row items-center justify-between">
                                <div className="w-[30%] flex  items-center justify-center gap-2" >
                                    <FontAwesomeIcon icon={faWallet} className='text-white z-20 relative'/>
                                    <h4 className="font-semibold text-white text-sm">Business Consulting</h4>
                                    
                                </div>
                                <div className="pl-2">
                                    <p className="lg:text-md text-sm text-base text-white">Expert in sales, marketing, and community development through the direct sales industry.  </p>
                                </div>
                            </div>
                        </div> 
                        <div className="bg-[#FAFAFA0D] lg:p-6  bg-no-repeat lg:bg-cover bg-auto bg-center lg:w-[65%]  md:w-[85%] md:w-[95%] w-full ml-auto  wow slideInRight  transition duration-1000 ease-in-out "  style={{backgroundImage: "url('/assets/rectangle.png')"}}>
                            <div className="flex flex-col gap-5 lg:gap-0 lg:flex-row  items-center justify-between mt-5">
                                <div className="w-[50%] flex  items-center justify-center gap-2">
                                    <FontAwesomeIcon icon={faLock} className=' text-white  z-20 relative'/>
                                    <h4 className="font-semibold text-white text-sm ">Blockchain Pioneer</h4>
                                    
                                </div>
                                <div className="pl-2 pb-2">
                            <p className="lg:text-md text-sm text-base text-white">Shaping the future of crypto with a powerful new tool that offers transparency in crypto trading and exchanges, helping communities dive deep into cryptocurrency projects with confidence.    </p>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
                </div>

                {/* His vision */}

                <div className="SECTION-3 bg-gradient-to-r from-[#0243AD] to-[#271237] lg:pt-12 pt-8 overflow-hidden" >
                    
                    <div className="SECTIN-4 container mx-auto   relative" id="about">
                        <div className="bg-no-repeat bg-center lg:block hidden bg-contain w-[500px] h-[500px] absolute top-[-240px] right-[-70px]" style={{backgroundImage:"url('/assets/vission-two.png')"}}></div>
                        <div className="bg-no-repeat bg-center lg:block hidden bg-contain w-[500px] h-[500px] absolute bottom-[-100px] left-[-65px]" style={{backgroundImage:"url('/assets/vission.png')"}}></div>
                        <div className="flex items-center w-full sm:flex-row flex-col">
                            <div className="lg:w-[40%] md:w-[55%] w-full  "  data-aos-duration="3000" data-aos="fade-right" >
                                <span className="flex items-center justify-center md:mb-2 mb-6"><img src="./assets/about.png" className="md:h-[550px] h-[300px] w-auto object-contain animated-bg wow bounceInRight delay-300"/></span>
                            </div>
                            <div className="lg:w-[60%] md:w-[45%] w-full " data-aos-duration="3000" data-aos="fade-left">
                                <h3 className="text-white md:text-5xl text-3xl mb-4">His Vision </h3>
                                <p className="text-white md:text-2xl text-lg w-full mx-auto tracking-widest wow bounceInRight delay-400">Ram Sumiran Pal is committed to fostering trust and transparency in the digital world, using blockchain to build stronger, more connected communities.</p>
                            </div>
                        </div>
                    </div>

                    {/* mission */}
                      
                    <div className="SECTION-5 block lg:py-16 py-8  px-3 lg:px-0 group" id="mision">
                     <div className="text-white font-play fa-fade lg:hidden block ">
                      <p
                        className="text-center opacity-100 group-hover:opacity-0"
                        
                      >
                        Tap Me
                      </p>
                      </div>
                    <div className="container mx-auto group-hover:opacity-100 opacity-0 transition duration-1000">
                    
                            <h3 className="text-white md:text-5xl text-3xl lg:my-12 md:my-8 my-4 animated-bg wow slideInLeft delay-500 ease-in-out">His Mission? </h3>
                            <div className="lg:w-[65%] md:w-[85%] w-full mx-auto border border-[#CBA952] shadow-lg shadow-[#CBA952] rounded-lg 2xl:p-24 md:p-16 p-6 animated-bg ease-in-out wow fadeInTop delay-500 ease-in-out">
                                <p className="md:text-3xl sm:text-2xl text-xl text-white tracking-widest">To empower people with the knowledge they need to thrive in the world of decentralized technology. </p>
                            </div>
                        </div>
                    </div>

                    {/* lets connect  */}

                    <div className="SECTION-6 block lg:py-12 py-8 group px-3 lg:px-0" id="contact">
                    <div className="text-white font-play fa-fade lg:hidden block ">
                      <p
                        className="text-center opacity-100 group-hover:opacity-0"
                        
                      >
                        Tap me To Contact!!
                      </p>
                      </div>
                        <div className="container mx-auto group-hover:opacity-100 opacity-0 transition duration-1000">
                            <h3 className="text-white lg:text-4xl sm:text-3xl text-[30px] lg:my-12 md:my-8 my-4">Made Easy to Connect   </h3>
                            <div className="border border-[#CBA952] lg:w-[75%] md:w-[85%] w-full mx-auto shadow-lg shadow-[#CBA952] rounded-lg md:p-8 p-4 animated-bg" >
                                <h4 className="py-4 text-white md:text-2xl text-xl">LET’S CONNECT</h4>
                                <form onSubmit={handleSubmit}>
                                    <div className="lg:w-[65%] md:w-[85%] w-full mx-auto">
                                        <input class="my-2 placeholder:text-white-400 block bg-transparent w-full border border-white-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white" placeholder="Name" required value={emailData.name}
        onChange={handleChange} type="text" name="name"/>
                                        <input class="my-2 placeholder:text-white-400 block bg-transparent w-full border border-white-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white" placeholder="Email"required value={emailData.email}
        onChange={handleChange} type="text" name="email"/>
                                        <input class="my-2 placeholder:text-white-400 block bg-transparent w-full border border-white-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white" placeholder="Subject" required value={emailData.subject}
        onChange={handleChange} type="text" name="subject"/>
                                        <textarea class="my-2 placeholder:text-white-400 block bg-transparent w-full border border-white-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white" placeholder="Message" required type="text" value={emailData.message}
        onChange={handleChange} name="message"></textarea>
                                    </div>
                                    <div className="flex items-center justify-center w-full lg:my-4 my-2">
                                        <button className="py-2 md:px-16 px-10 rounded-md bg-[#FFFFFF63] text-white sm:text-lg text-base">Get in Touch with RAM</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                    {/* latest blogs */}

                    <div className="SECTION-7 block lg:py-12 py-8 " id="blog">
                        <div className="container mx-auto ">

                            <h3 className="flex justify-center w-[350px] mx-auto text-white xl:text-4xl text-3xl  border border-transparent inline border-b-[#216FEE] lg:my-6 my-4 pb-4">LATEST BLOGS</h3>

                            <div className="flex flex-col lg:flex-row gap-10 justify-center px-3">
                               
                                <div class="relative grid content-between my-6 bg-transparent shadow-sm border border-slate-200 rounded-lg lg:w-96">
                                <div class="relative flex justify-center overflow-hidden text-white rounded-t-lg bg-[#adb7cf]">
                                  <img className="w-4/12" src="./assets/founder.png" alt="card-image" />
                                </div>
                                <div class="p-4">
                                  <h6 class="mb-2 text-white text-xl font-semibold">
                                  From Banking to Blockchain:
                                  </h6>
                                  <h6 class="mb-2 text-white text-sm font-semibold">
                                  My Journey of Taking Big Risks and Reaping Bigger Rewards — Ram Sumiran Pal (RSP).  </h6>
                                  <hr></hr>

                                  <p class="text-white font-Playfair leading-normal font-light mt-2">
                                  Here are moments in life when you have to choose between the comfort of what you know and the uncertainty of what could be. For me, that moment came when I decided to leave behind a secure job in banking to venture into the unknown world of blockchain.                                </p>
                                </div>
                                <div class="px-4 pb-4 pt-0 mt-2 z-20">
                                  <a href="https://medium.com/@ramspal2508/from-banking-to-blockchain-my-journey-of-taking-big-risks-and-reaping-bigger-rewards-ram-1e7437b3b06a" target="_blank" ><button class="rounded-md bg-slate-800 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button">
                                    Read more
                                  </button>
                                  </a>
                                </div>
                              </div> 
                                <div class="relative grid content-between my-6 bg-transparent shadow-sm border border-slate-200 rounded-lg lg:w-96">
                                <div class="relative  overflow-hidden text-white rounded-t-lg">
                                  <img src="./assets/blog-2.png" alt="card-image" />
                                </div>
                                <div class="p-4">
                                  <h6 class="mb-2 text-white text-xl font-semibold">
                                  Lessons Learned from My Father:
                                  </h6>
                                  <h6 class="mb-2 text-white text-sm font-semibold">
                                  Wisdom That Shaped My Career — Ram Sumiran Pal (RSP)  </h6>
                                  <hr></hr>

                                  <p class="text-white font-Playfair leading-normal font-light mt-2">
                                  One of the first things my father taught me was the importance of staying humble, no matter how successful you become. From working in direct sales to becoming a blockchain expert and community builder, my father’s wisdom has been the foundation of my success.                                  </p>
                                </div>
                                <div class="px-4 pb-4 pt-0 mt-2 z-20">
                                  <a href="https://medium.com/@ramspal2508/lessons-learned-from-my-father-wisdom-that-shaped-my-career-ram-sumiran-pal-rsp-b5ef39031764" target="_blank" ><button class="rounded-md bg-slate-800 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button">
                                    Read more
                                  </button>
                                  </a>
                                </div>
                              </div>  
                              
                                
                                <div class="relative grid content-between my-6 bg-transparent shadow-sm border border-slate-200 rounded-lg lg:w-96">
  <div class="relative  overflow-hidden text-white rounded-t-lg">
    <img src="./assets/blog-1.png" alt="card-image" />
  </div>
  <div class="p-4">
    <h6 class="mb-2 text-white text-xl font-semibold">
    Understanding the Speak Asia Controversy:
    </h6>
    <h6 class="mb-2 text-white text-sm font-semibold">
    What I Learned and How It Shaped My Values — Ram Sumiran Pal (RSP)    </h6>
    <hr></hr>
    <p class="text-white font-Playfair leading-normal font-light mt-2">
    When I joined SpeakAsiaOnline, I believed in its vision to revolutionize market research and community development. However, as the company grew, challenges and controversies emerged.
    </p>
  </div>
  <div class="px-4 pb-4 pt-0 mt-2 z-20">
    <a href="https://medium.com/@ramspal2508/understanding-the-speak-asia-controversy-what-i-learned-and-how-it-shaped-my-values-ram-sumiran-9ca69565f1ae" target="_blank" ><button class="rounded-md bg-slate-800 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button">
      Read more
    </button>
    </a>
  </div>
</div>  

                            </div>
                        </div>
                    </div>


                    {/* meditation section */}

                    <div className="SECTION-8 bg-no-repeat bg-center bg-cover relative lg:py-32 lg:py-20" style={{backgroundImage:"url('/assets/medi.png')"}} id="media">
                    <div className="bg-no-repeat bg-center lg:block hidden bg-contain w-[500px] h-[500px] absolute top-[-240px] right-[-70px]" style={{backgroundImage:"url('/assets/vission-two.png')"}}></div>
                    <div className="bg-no-repeat bg-center lg:block hidden bg-contain w-[500px] h-[500px] absolute bottom-[-100px] left-[-105px] rotate-180" style={{backgroundImage:"url('/assets/vission.png')"}}></div>
                        <div className="container mx-auto lg:pb-32 pb-18">
                            <h2 className="py-4 text-white md:text-3xl text-2xl tracking-wider ">Why Meditation? </h2>
                            <p className="text-white lg:w-[75%] md:w-[85%] sm:w-[92%] w-full mx-auto tracking-widest md:text-2xl sm:text-xl text-lg my-4 lg:pb-12 pb-8 border-transparent border-b-[#CBA95238]">Meditation isn’t just a practice for RSP; it’s his secret weapon for success. He believes in its power to bring clarity, focus, and resilience—qualities essential for thriving in both business and life. </p>
                        </div>

                        <Footer/>
                    </div>

                    

                </div>

            </div>

            
        </>
    )
};
export default LandingPage;