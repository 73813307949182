import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLink, faLocationDot,faLock} from '@fortawesome/free-solid-svg-icons';

function Footer() {
    return (
        <>
            <div className="block font-Monstratt lg:py-20 sm:py-12 py-6">
                <div className="container mx-auto w-full">
                    <div className="mx-auto flex items-center justify-center lg:justify-between md:flex-row flex-wrap lg:w-[85%] sm:w-[95%] w-full">
                        <div className="lg:w-[30%] md:w-[45%] sm:w-[80%] w-full mb-4">
                            <p className="text-white text-md font-semibold mb-2"><FontAwesomeIcon icon={faEnvelope} className="text-[#4C75A1] fa-xl mr-2" />EMAIL US</p>
                            <a href="#" className="text-white text-base font-poppins">ramspal2508@gmail.com</a>
                        </div>
                        <div className="lg:w-[30%] md:w-[45%] sm:w-[80%] w-full mb-4">
                            <h6 className="text-white font-semibold mb-2">Follow Us On</h6>
                            <div className="flex items-center justify-between px-5 lg:px-0">
                                <a href="https://www.facebook.com/ramsumiranpal.official/" target="_blank" className="">
                                    <span>
                                        <img src="./assets/Icon.png"/>
                                    </span>
                                </a>
                                <a href=" https://x.com/ram_pal08" target="_blank" className="">
                                    <span>
                                        <img src="./assets/Icon (1).png"/>
                                    </span>
                                </a>
                                <a href="https://www.instagram.com/ramsumiranpalofficial/" target="_blank" className="">
                                    <span>
                                        <img src="./assets/Icon (2).png"/>
                                    </span>
                                </a>
                                <a href="https://www.youtube.com/channel/UCHiJ44Rxt9O5xwnXUTlHeqg" target="_blank" className="">
                                    <span>
                                        <img src="./assets/Icon (3).png"/>
                                    </span>
                                </a>
                                <a href="https://www.linkedin.com/in/ram-sumiran-pal-baabbb134" target="_blank" className="text-white ">
                                <i class="fa-brands fa-linkedin-in fa-2xl"></i>
                                </a>
                            </div>
                        </div>
                        <div className="lg:w-[30%] md:w-[45%] sm:w-[80%] w-full mb-4">
                            <p className="text-white font-semibold mb-2"><FontAwesomeIcon icon={faLocationDot}  className="text-[#4C75A1] fa-xl mr-2"/>ADDRESS</p>
                            <a href="#" className="text-white text-base font-poppins">Mumbai, India</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Footer;