// Header
import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBarsStaggered } from '@fortawesome/free-solid-svg-icons';

export default function Header() {
  const password = "3ac4b235631031b87cb38810ba1eff44-826eddfb-3d6e1e1a"
  const username = "postmaster@ramsumiranpal.in"
  const [isDropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    if (isDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdown]);


  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='font-poppins'>
      <div className="hidden lg:block z-10 relative">
        <div className="flex justify-between  bg-transparent border-transparent border-2 border-b-[#828282] z-10 font-Montserrat">
          <div>
            <span><img src='./assets/rsp-logo.png' className='w-[124px] h-[80px] object-contain'/></span>
          </div>

          <div className="w-[100%] flex justify-end 2xl:gap-12 xl:gap-10 lg:gap-8 px-2 py-4 items-center font-semibold">
            
          <NavLink
          onClick={() => scrollToSection('home')}
              to="/"
              className="transition duration-500  text-white lg:text-sm 2xl:text-lg font-Montserrat rounded-full px-5 z-10 tracking-wider hover:bg-[#30477b] hover:border hover:border-white hover:font-Montserrat"
            >
              Home
            </NavLink>

            <NavLink
            onClick={() => scrollToSection('about')}
              to=""
              className="transition duration-500 text-white lg:text-sm 2xl:text-lg font-Montserrat z-10  rounded-full px-5 tracking-wider hover:bg-[#30477b] hover:border hover:border-white hover:font-Montserrat"
            >
              About
            </NavLink>

            <NavLink
            onClick={() => scrollToSection('blog')}
                to=""
              className="transition duration-500 text-white lg:text-sm 2xl:text-lg font-Montserrat rounded-full px-5 z-10 tracking-wider hover:bg-[#30477b] hover:border hover:border-white hover:font-Montserrat"
            >
             Blog
            </NavLink>
            
          

            <NavLink
            onClick={() => scrollToSection('media')}
              to=""
              className="transition duration-500 text-white lg:text-sm 2xl:text-lg font-Montserrat rounded-full px-5 z-10 tracking-wider hover:bg-[#30477b] hover:border hover:border-white hover:font-Montserrat"
            >
             Media
            </NavLink>

            <NavLink
            onClick={() => scrollToSection('contact')}
                to=""
              className="transition duration-500  text-white lg:text-sm 2xl:text-lg font-Montserrat rounded-full px-5 z-10 tracking-wider hover:bg-[#30477b] hover:border hover:border-white hover:font-Montserrat"
            >
             Contact
            </NavLink>
            
          </div>
        
        </div>
      </div>

      <div className='block lg:hidden'>
        <div className='flex justify-between sm:px-10 px-6 border-2 border-b-[#828282] py-4 font-Montserrat  bg-[#01327F]'>
          <div>
          <span><img src='./assets/rsp-logo.png' className='w-[80px] h-[75px] object-contain'/></span>
          </div>
          <div className='ease-in-out duration-300 z-20 relative flex items-center'>
            <button className='z-20 relative' onClick={handleDropdown} ref={buttonRef}>
              <FontAwesomeIcon icon={faBarsStaggered} className=' text-white md:fa-2xl fa-xl z-20 relative'/>
              
            </button>
          </div>
          {isDropdown && (
            <div
              className="dropdown-content text-black z-50 md:w-[20rem] sm:w-[12rem] w-[10rem] absolute right-5 mt-2 top-20 animate__animated animate__fadeInUp ease-in duration-300 delay"
              ref={dropdownRef}
            >
              <div className="flex flex-col font-Montserrat gap-3 lg:text-sm 2xl:text-lg bg-blue-500 text-white rounded-lg p-5">
                <NavLink to="/"           
                onClick={() => {
                  scrollToSection('home');
                  setDropdown(false)
                }}
                >
                  HOME
                </NavLink>
                <NavLink
                  to="/"
                  // to="/token-dashboard"
                  onClick={() =>{
                    setDropdown(false)
                    scrollToSection('about')
                  }} 
                >
                  About
                </NavLink>

                <NavLink
                    to="/"
                  // to="/Support-tickets"
                  onClick={() =>{
                     scrollToSection('blog');
                     setDropdown(false)
                    }}
                >
                  Blog
                </NavLink>

                <NavLink
                  to="/"
                  onClick={() => {scrollToSection('media');
                    setDropdown(false)
                  }}
                >
                  Media
                </NavLink>
              
                <NavLink
                  // to="/about-us"                
                    onClick={() => {scrollToSection('contact')
                      setDropdown(false)
                    } } 

                >
                 Contact
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}