import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './components/LandingPage';
import Header from './components/Header'; 
import Footer from './components/Footer';

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<LandingPage/>}/>
        <Route path="/header" element={<Header/>}/>
        <Route path="/footer" element={<Footer/>}/>
      </Routes>
    </div>
  );
}

export default App;
